import React from 'react'

export const Context = React.createContext({

});

// updateContext is the single function that handles all AJAX calls that alter state
// See "Provider" below for the state items themselves
const updateContext = (auth, endpoint, setter, options={}) => {
    if (auth.id_token) {
	if (options.headers) {
	    options.headers.Authorization = auth.id_token;
	}
	else {
	    options.headers={Authorization: auth.id_token};
	}
    }

    console.log(`Api call ${endpoint}`, options);
    fetch(endpoint, options)
        .then(response=>{
            console.log(`API ${endpoint} response ${response.status} ${response.statusText}`);
	    let json;
	    try {
		json = response.json();
		//console.log('Decoded response is ', JSON.stringify(json,null,'   '));
	    }
	    catch (e) {
		console.warn('Response was not valid JSON: ',e);
		json = response.body();
	    }
            return json
        })
        .then(json=>{
	    console.log(json);
            //console.log(`API ${endpoint} response body`, JSON.stringify(json,null,'    '));
	    //setter(json);
        })
	.catch(err=>console.error(`API ${endpoint} error`, err));
}


const Provider = (props) => {

      // These are the state items which are provided by this context.
      // auth and authConfig are needed for login.
      // ping/setPing is an example of a single item that is fetched via api
      
    const [auth,setAuth] = React.useState({});
    const [authConfig,setAuthConfig] = React.useState({});
    const [ping,setPing] = React.useState({});

    // add more context items here

    const context = {
	auth,setAuth,
	ping,setPing,
	authConfig,setAuthConfig,
	updateContext:(endpoint,setter,options={})=>updateContext(auth,endpoint,setter,options),
    };

    console.log('Provider.tsx injects context:', context);

    return (<Context.Provider value={context}>
	    {props.children}
	    </Context.Provider>);
}

export default Provider;

    
