import React, {useContext} from 'react';
//import React, { ReactElement } from 'react';
//import logo from './logo.svg';
import './App.css';

import '@elastic/eui/dist/eui_theme_light.css';
//import { EuiProvider } from '@elastic/eui';
import Provider,{Context} from './Provider';

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiPageTemplate,
  //EuiPageTemplateProps,
  //EuiPageHeaderProps,
  //EuiPageSidebarProps,
} from '@elastic/eui';


const  Home= () => {
    const context = useContext(Context);
    console.log('Context object in Home() is', context);
    const {updateContext,/*ping,*/setPing} = context;

    return (
	<EuiPageTemplate
	    panelled={true}
	>
    	    <EuiPageTemplate.Sidebar sticky={true}>
		<EuiText>Sidebar go here</EuiText>
            </EuiPageTemplate.Sidebar>
            <EuiPageTemplate.Header rightSideItems={[<EuiButton size="s">Login</EuiButton>]} ><EuiText>Title go here</EuiText></EuiPageTemplate.Header>
	    <EuiPageTemplate.Section grow={false}>
		<EuiFlexGroup key="buttons">
		    <EuiFlexItem grow={false}>
			<EuiButton onClick={()=>{updateContext('api/ping', setPing)}}>API Ping</EuiButton>
		    </EuiFlexItem>
		</EuiFlexGroup>
	    </EuiPageTemplate.Section>
	    <EuiPageTemplate.Section><EuiText>Main content go here (growable section)</EuiText></EuiPageTemplate.Section>
	    <EuiPageTemplate.BottomBar paddingSize="s">
		<EuiText>This is the bottom bar</EuiText>
	    </EuiPageTemplate.BottomBar>
	</EuiPageTemplate>
    )
}
    

const App = () => {

    // see Provider.tsx for how to add more operations like ping/setPing

    return (
	<Provider>
	    <Home/>
      </Provider>
  );
};

export default App;
